const YoutubeVideo = ({ videos }) => {
  return videos.map((video) => {
    let videoEmbedCode = video.link.split("v=")[1];
    return (
      <div key={video.id} className="video-container">
        <iframe
          src={`https://www.youtube.com/embed/${videoEmbedCode}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  });
};

export default YoutubeVideo;
