import React from "react";
function About() {
  return (
    <section className="bn--section about-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 left_div">
            <div className="context">
              <h2 className="section-title align-items-start">
                <div className="title-content">
                  WHY<span className="bold-title"> CHOOSE</span> US
                </div>
                <div className="under-section-title">
                  <div className="left-line"></div>
                  <div className="right-line"></div>
                </div>
              </h2>
              <p className="bn--section-subtitle">Taking pride in our work</p>
              <p className="bn--section-description">
                The team at Artech has been working together for many years,
                which has allowed us to become familiar with each other. We have
                created a balanced work environment that makes us efficient.
                Because of this familiarity we're able to develop effective
                software as a team.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 about-section-right">
            <div className="row">
              <div className="left-square-bloks col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="squareBlue square-block">
                  <img src="/images/icons/group_30.svg" className="iconStyle" />
                  <div className="square-title">Efficient</div>
                  <div className="square-description">
                    After many projects, the team at Artech has learned to work
                    together very efficiently. This is why we get you the best
                    result quickly.
                  </div>
                </div>
                <div className="squareGreen bottom-square square-block">
                  <img src="/images/icons/group_31.svg" className="iconStyle" />
                  <div className="square-title">Effective</div>
                  <div className="square-description">
                    The experiences we've had in web development for businesses
                    have allowed us to create a strategize the perfect plan for
                    businesses in all industries.
                  </div>
                </div>
              </div>
              <div className="right-square-bloks col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="squareGreen  square-block">
                  <img src="/images/icons/group_32.svg" className="iconStyle" />
                  <div className="square-title">Customer Service</div>
                  <div className="square-description">
                    The goal for us is to always leave the customer happy under
                    any circumstance. We are available to speak over the phone
                    24/7. We prioritize your business needs and we are focused
                    on helping you grow brand.
                  </div>
                </div>
                <div className="squareBlue bottom-square square-block">
                  <img src="/images/icons/group_33.svg" className="iconStyle" />
                  <div className="square-title">Quality</div>
                  <div className="square-description">
                    We take pride in doing quality work. All our projects go
                    through multiple stages of development and debugging to
                    create the perfect applictation for you.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default About;
