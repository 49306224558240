import React from "react"

const Features = ({data}) => {
    return(
        <div>
            <div className="features_images"><img  src={data.image}/></div>

    <div className="feauters-title">
        <div className="feauters-text-title">{data.title}</div>
        <img src="/images/title.svg" />
    </div>
    <div className="software-feauters-text">
   {data.description}
        </div>
        </div>
    )
}
export default Features