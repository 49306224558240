import React from "react";
import { SoftwareData } from "../ProductData";
import { HardwareData } from "../ProductData";
import Features from "../ProductFeatures";

const ProductDetails = ({
  productName,
  boldTitle,
  regularTitle,
  description,
  image,
}) => {
  return (
    <div className="software-page container">
      <div className="row">
        <div className="col-md-12 col-xs-12 col-sm-12 col-lg-6 col-xl-6 ">
          <div className="software-left">
            <div className="software-title">
              <span>{boldTitle}</span>
              {regularTitle}
            </div>
            <div className="software-text">{description}</div>
          </div>
        </div>
        <div className="col-md-12 col-xs-12 col-sm-12 col-lg-6 col-xl-6">
          <div className="software-png">
            <img src={image} className="png-software" />
          </div>
        </div>
      </div>
      <div className="software-feauters"></div>
      <div className="software-feauters-title">
        {boldTitle}
        {regularTitle} <span></span>
      </div>
      <div className="row">
        {productName === "software"
          ? SoftwareData.map((data, i) => (
              <div key={i} className="col-md-12 col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                <Features data={data} />
              </div>
            ))
          : HardwareData.map((data, i) => (
              <div key={i} className="col-md-12 col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                <Features  data={data} />
              </div>
            ))}
      </div>
    </div>
  );
};

export default ProductDetails;
