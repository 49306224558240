const Banner = () => {
  return (
    <section className="bn--section first-bn-section">
      <div className="banner primary-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 banner-description ">
              <h1 className="section-title text-left">Business Solutions</h1>
              <p>
                Artech is a full-time SBE IT Solutions provider. Providing IT
                products and services to a myriad of customers.
              </p>
              <a href="/services" className="bn--learn-btn">
                Our Services
              </a>
            </div>
            <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12">
              <img src="/images/green_pgot.png" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
