import Header from "../header";
import Footer from "../footerfolder";
import { data } from "./Data";

const Portfolio = ({ color }) => {
  return (
    <div>
      <Header color={color} />
      <div className="container bn--portfolio_section">
        <div className="row">
          <div className="col-xl-12">
            <div className="bn-portfolio">
              <h2 className="bn-portfolio__title">Web Design Portfolio</h2>
              <div className="under-section-title">
                <div className="left-line"></div>
                <div className="right-line"></div>
              </div>
              <p className="bn-portfolio__text">
                Listed below are some of our recent projects we've completed.
              </p>
            </div>
          </div>
        </div>
        {data.map((item, index) => {
          let { img, alt, name, portfolioLink, desc } = item;
          return (
            <div
              className={`row bn-portfolio_block justify-content-center ${
                index % 2 === 1 && "bn-portfolio_reverse"
              }`}
              key={index}
            >
              <div className="col-xl-4 offset-xl-1 col-lg-5 col-md-6 col-sm-10 col-10">
                <div className="bn-portfolio_item">
                  <a
                    href={portfolioLink}
                    target="_blank"
                    className="bn-portfolioImg_holder"
                  >
                    <figure className="bn-portfolio-member">
                      <img src={img} className="bn-portfolioImg" alt={alt} />
                    </figure>
                  </a>
                  <img
                    src="/images/ellipse.png"
                    alt="ellipse"
                    className="bn-ellipse_img"
                  />
                </div>
              </div>
              <div className="col-xl-6 offset-xl-1 col-lg-7 col-md-6 col-sm-12 col-10">
                <div className="bn-portfolio_person">
                  <a
                    className="bn-portfolio_name"
                    target="_blank"
                    href={portfolioLink}
                  >
                    {name}
                  </a>
                  <img src="/images/title.svg" />
                </div>
                <p className="bn-portfolio_info">{desc}</p>
              </div>
            </div>
          );
        })}
      </div>
      <Footer />
    </div>
  );
};

export default Portfolio;
