import Banner from "../banner";
import OurService from "../ourServices";
import About from "../aboutUs";
import ContactUs from "../contactUs";
import FaQ from "../faq";
import Header from "../header";
import SubscribeForm from "../subscribeForm";
import FooterOne from "../footerfolder";

const HomePage = ({logo}) => {
  return (
    <div className="homepage">
      <Header logo={logo} />
      <Banner />
      <OurService />
      <About />
      <ContactUs />

      <FooterOne />
    </div>
  );
};

export default HomePage;
