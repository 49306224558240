import Header from "../header";
import Footer from "../footerfolder";

const SupportPage = ({color}) => {
    return (

        <div>
            <Header color={color} />
            <div>SupportPage</div>
            <Footer />
        </div>
       
    )
}

export default SupportPage;