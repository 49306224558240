import React from "react";
import ImgWithTextInnerRows from "../ImgWithTextInnerRows";
function OurService() {
  return (
    <section className="bn--section">
      <div className="container">
        <h2 className="section-title">
          <div className="title-content">
            Our <span className="bold-title"> Services</span>
          </div>
          <div className="under-section-title">
            <div className="left-line"></div>
            <div className="right-line"></div>
          </div>
        </h2>
        <div className="row bn--image-with-text align-items-center">
          <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 section-left-img">
            <img src="images/green_ill.png" className="img-fluid" />
          </div>
          <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 section-left-text">
            <ImgWithTextInnerRows
              img="images/icons/group_10.svg"
              boldtitle="Soft"
              regularTitle="Ware"
              description="We do web design, mobile app development, UI/UX design, and everything in between. We work with you to design and develop software that we integrate with your business to take it to the next level."
              url="/software"
            />
            <ImgWithTextInnerRows
              img="images/icons/group_12.svg"
              boldtitle="Hard"
              regularTitle="Ware"
              description="We are a leading distributer of industrial networking devices and Industrial Internet of Things (IIOT) related solutions."
              url="/hardware"
            />
            {/* <ImgWithTextInnerRows  img="images/icons/group_11.svg" boldtitle="Marketing"  description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et…"  url="/marketing" /> */}
          </div>
        </div>
      </div>
    </section>
  );
}
export default OurService;
