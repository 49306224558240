import React from "react";
import ProductDetails from "../ProductDetails";
import SupportPlan from "../SuportPlan/supportPlan";
import Header from "../../header";
import ContactUs from "../../contactUs";
import Footer from "../../footerfolder";
const SoftwarePage = ({ logo, color }) => {
  return (
    <div>
      <Header logo={logo} color={color} />
      <ProductDetails
        image="/images/webwhite.jpg"
        productName="software"
        boldTitle="Soft"
        regularTitle="Ware"
        description="At Artech, we work with you to design and develop software that we integrate with your business to take it to the next level"
      />
      {/* <SupportPlan productName="software" /> */}
      <div className="contact-section">
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
};
export default SoftwarePage;
