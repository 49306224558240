const Team = () => {
  const teamData = [
    {
      image: "/images/arvin4.jpg",
      name: "Arvin Abrahamian",

      profession: "President",
      skills: [
        "HTML",
        "CSS",
        "React Js",
        "Media Production",
        "Market Planning",
        "Graphic Design",
      ],
    },
    {
      image: "/images/artur.jpg",
      name: "Artur Sargsyan",
      profession: "MERN stack Developer",
      skills: [
        "HTML",
        "CSS",
        "JS (ES6, ES7)",
        "React Js",
        "Node Js",
        "Express Js",
        "Shopify",
        "Liquid",
      ],
    },
    {
      image: "/images/shahen.jpg",
      name: "Shahen Babayan",
      profession: "Project Manager (Developer)",
      skills: ["HTML", "CSS", "JS (ES6, ES7)", "React Js", "Shopify"],
    },
    {
      image: "/images/dav.jpg",
      name: "Davit Margaryan",
      profession: "Shopify Expert",
      skills: ["HTML", "CSS", "JS (ES6, ES7)", "React Js", "Shopify"],
    },
    {
      image: "/images/arsen.jpg",
      name: "Arsen Matevosyan",
      profession: "UI/UX Designer",
      skills: ["Adobe XD", "Figma", "Photoshop", "Illustrator", "HTML", "CSS"],
    },
    {
      image: "/images/ani.jpg",
      name: "Ani Hayrapetyan",
      profession: "Sales Manager (Developer)",
      skills: ["Managmant Tools (Jira,Trello, etc.)", "HTML", "CSS"],
    },
  ];
  return (
    <section className="section members" id="tam">
      <div className="container">
        <header className="section-header text-center">
          <h2 className="section-title">Meet our team</h2>
        </header>
        <div className="team">
          {teamData.map((value, index) => (
            <div className="team-member" key={index}>
              <div className="card">
                <div className="card-front">
                  <div className="team-member__image">
                    <figure className="img-holder">
                      <img src={value.image} />
                    </figure>
                  </div>
                  <div className="team-member__body">
                    <h3 className="team-member__title">{value.name}</h3>
                    <span className="team-member__subtitle">
                      {value.profession}
                    </span>
                  </div>
                </div>
                <div className="card-back">
                  <h4 className="card-back-title text-center mb-3">
                    Our Skills
                  </h4>
                  <ul className="skills-list">
                    {value.skills.map((value, index) => (
                      <li key={index}>{value}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Team;
