export const SoftwareData = [
  {
    image: "../images/software/Group144.svg",
    title: "Web Design",
    description:
      "We design custom websites that are crafted to captivate, inspire, and attract new clients/customers. Designing and developing for numerous screen sizes and devices takes precision, which is why our team takes the necessary steps to make the perfect website for you.",
  },
  {
    image: "../images/software/netbox.png",
    title: "Custom Software",
    description:
      "The team at Artech specializes in creating custom software to help push your business to the next level. We work with the following: .NET, Python, Java, Django, Javascript, React.js, MySQL, etc.",
  },
  {
    image: "../images/software/phone.jpg",
    title: "Mobile App Development",
    description:
      "We design and develop custom mobile applications for iOS and Android platforms. Our mobile app developers have the expertise to create mobile applications using AI and ML technologies. We develop mobile apps in the React Native framework. ",
  },
  // {
  //   image: "../images/software/Group146.svg",
  //   title: "24/7 Support",
  //   description:
  //     "Lorem ipsum dolor sitgsdgsdf amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
  // },
];

export const HardwareData = [
  {
    image: "../images/software/Group152.svg",
    title: "Products We Provide",

    description:
      "A few of the products we provide: Networking & Computing, Industrial Networking, Remote Access, Industrial Ethernet, Ethernet Switches, Cellular Routers and Gateways, Wireless Radios, Remote Access, Cabling and Connectivity, Serial Device Servers & Protocol Converters, IoT Gateways and Sensors",
  },
];
