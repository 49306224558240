const Banner = () => {
  return (
    <div className="banner banner_about">
      <div className="container">
        <div className="section-header section-header__light">
          <span className="section-subtitle">About</span>
          <h1 className="section-title">
            <span>Artech provides quality services,</span>
            <span>backed by an experienced team</span>
          </h1>
        </div>
      </div>
      {/* <div className="img-holder">
                <img src="/images/moon-flag.svg" /> 
            </div> */}
    </div>
  );
};

export default Banner;
