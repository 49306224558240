import React from "react";
import Navbar from "./navbar/navbar";

function Header({logo, color}) {
  return (
        <Navbar logo={logo}  color={color}/>

  );
}
export default Header;
