import React, {useEffect} from "react";
import Routes from "./Routing";

import 'react-chat-widget/lib/styles.css';
import { useRoutes } from 'hookrouter';
function App() {
  const routeResult = useRoutes(Routes);

  return (
    <div className="bn--container">
       
     {routeResult}
    </div>
  );
}

export default App; 
