import React from "react";
import HomePage from "../Components/homePage";
import ServicesPage from "../Components/servicePage";
import SoftwarePage from "../Components/Product/softwarePage";
import HardwarePage from "../Components/Product/hardwarePage";
import AboutPage from "../Components/aboutPage";
import ContactPage from "../Components/contactPage";
import FaqPage from "../Components/faqPage";
import MarketingPage from "../Components/marketingPage";
import SupportPage from "../Components/supportPage";
import Portfolio from "../Components/Portfolio"

const fontColor = {
  black: "black",
  white: "white",
};
const logo = {
  black: "/images/site-logo.png",
  white: "/images/White.png",
};
const Routes = {

  "/": () => <HomePage logo={logo.white} color={fontColor.white} />,
  "/services": () => <ServicesPage logo={logo.white} color={fontColor.white} />,
  "/software": () => <SoftwarePage logo={logo.black} color={fontColor.black} />,
  "/hardware": () => <HardwarePage logo={logo.black} color={fontColor.black} />,
  "/about-us": () => <AboutPage logo={logo.white} color={fontColor.white} />,
  "/contact-us": () => <ContactPage logo={logo.black} color={fontColor.black} />,
  "/portfolio": () => <Portfolio color={fontColor.black} />,
  "/faq": () => <FaqPage logo={logo.black} color={fontColor.black} />,
  "/marketing": () => <MarketingPage  logo={logo.black} color={fontColor.black} />,
  "/support": () => <SupportPage color={fontColor.black} />,
};
export default Routes;
