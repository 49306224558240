import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { init, sendForm } from "emailjs-com";
init("user_L9NjAy4AkldNQl5bq0ETo");
function ContactUs() {
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = (data) => console.log(data);

  const [contactNumber, setContactNumber] = useState("000000");

  const generateContactNumber = () => {
    const numStr = "000000" + ((Math.random() * 1000000) | 0);
    setContactNumber(numStr.substring(numStr.length - 6));
  };

  const onSubmitt = (data) => {
    // console.log(data);
    generateContactNumber();
    sendForm("service_sjkdf2p", "template_bs300pl", "#contact-form").then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
  };
  return (
    <section className="bn--section contact-section-container">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-md-12 m-auto contact-section-iiner-container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 contact-left-side">
                <h2 className="section-title align-items-start">
                  <div className="title-content">Contact Us</div>
                  <div className="under-section-title">
                    <div className="left-line"></div>
                    <div className="right-line"></div>
                  </div>
                </h2>
                <div className="contact-img-container col-lg-10 col-md-12 col-sm-12 col-xs-12 m-auto">
                  <img src="/images/group_56.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 right-side">
                <form
                  id="contact-form"
                  className="contact-section-form"
                  onSubmit={handleSubmit(onSubmitt)}
                >
                  <input
                    type="hidden"
                    name="contact_number"
                    value={contactNumber}
                  />
                  <div className="form-group contact-form-fileds">
                    <input
                      type="text"
                      name="user_name"
                      className="form-control"
                      placeholder="Name"
                      maxLength="30"
                    />
                  </div>
                  <div className="form-group contact-form-fileds">
                    <input
                      type="text"
                      className="form-control"
                      name="user_email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group contact-form-fileds">
                    <textarea
                      className="form-control"
                      placeholder="Messege"
                      name="message"
                      rows="10"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <button className="subButton">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ContactUs;
