import Header from "../header";
import Footer from "../footerfolder";
import FaQ from "../faq";

const FaqPage = ({logo, color}) => {
    return (

        <div>
            <Header logo={logo} color={color} />

                <div className="faq-page-container">
                    <FaQ />
                </div>
            <Footer />
        </div>
       
    )
}

export default FaqPage;