export const ServiceItemsData = [
  {
    image: "images/service/group_97.svg",
    boldTitle: "Soft",
    regularTitle: "Ware",
    description:
      "At Artech, we work with you to design and develop software that we integrate with your business to take it to the next level. We develop a plan based on your businesses digital marketing needs. The concept design is later developed into a fully functional application. ",
    url: "/software",
    position: "left",
  },
  {
    image: "images/service/group_96.svg",
    boldTitle: "Hard",
    regularTitle: "Ware",
    description:
      "We are a VAR and distributor of industrial networking devices, Wireless Broadband Equipment and Wireless and Industrial Internet of Things (IIOT) related solutions.",
    url: "/hardware",
    position: "right",
  },
  // {
  //   image: "images/service/group_98.svg",
  //   boldTitle: "Marketing",
  //   description:
  //     "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
  //   url: "#",
  //   position: "left",
  // },
];
