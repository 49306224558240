import React from "react"

function ChoosePlan({ productName }){
    return(
        <div >
          <div className="container">
<div className="support-component-title ">
        Find the plan{" "}
        <b>That’s </b>
      </div>
<span className="support-component-title-bold">
          
          Right For You{" "}
        </span></div>
      <div className="background-png">
        
            <div>
                <div className="get-free">
                    Choose Your Plan
                </div>
               
        </div>
        </div>
        </div>
    )
}
export default ChoosePlan;