export const data = [
  {
    img: "https://cdn.shopify.com/s/files/1/0071/9454/2169/files/JIBBON-62.jpg",
    alt: "portfolio_image1",
    name: "Jibbon Key Organaiser",
    portfolioLink: "https://www.jibbonkey.com",
    desc: `Wherever our partners need Shopify custom theme development, we’re all up in assisting them
        Bring the best side of your website to the forefront. Use Shopify theme to create an unforgettable impression on your customers and attract prospects. Bespoke theme will fine-tune your visual brand identity, and your clients will hear your distinctive voice. With Shopify template development, you get a refined website design. You also get a tool to advance customer experience. `,
  },
  {
    img:
      "https://cdn.shopify.com/s/files/1/2754/8070/files/b8_5d8cf9ab-a7a0-463e-8568-bf18470a5e6c_1400x.progressive.jpg",
    alt: "portfolio_image3",
    name: "AusAir - Leading High Tech Filtration Mask",
    portfolioLink: "https://shopausair.com",
    desc: `Concentrate on your business goals. We take everything else on us. No more worries. We set up shopify sites and implement configurations. We conduct maintenance works and help your store run fast and satisfy your clients.
        Our partner required integrating third-party applications and services, analytics setup to expand sales channels and markets, bringing new customers, providing users new interaction methods`,
  },
  {
    img:
      "https://cdn.shopify.com/s/files/1/0854/5504/files/Web_banner_1_2000x.jpg",
    alt: "portfolio_image3",
    name: "STAX. Activewear & Leisurewear for Women & Men",
    portfolioLink: "https://stax.com.au",
    desc: `
        Our partner required integrating third-party applications and services, analytics setup to expand sales channels and markets, bringing new customers, providing users new interaction methods`,
  },
  {
    img:
      "https://cdn.shopify.com/s/files/1/0854/5504/files/Web_banner_1_2000x.jpg",
    alt: "portfolio_image3",
    name: "Libredo Economy and Society",
    portfolioLink: "https://www.libredo.se",
    desc: `Libredo got its start in the middle of a burning pandemic, in response to all the challenges around us, Libredo wanted to be a bright spot. Something more than just an accounting firm - someone to get tips and advice from when it's needed the most. We are passionate about the sole entrepreneur or the small company.
        Every time you analyze your competitors, look at what makes YOU different. Use these differences to integrate them into your website. We will support your idea and help you bring it into action.`,
  },
];
