import Header from "../header";
import Banner from "./aboutBanner";
import Team from "./team";
import Footer from "../footerfolder";
const AboutPage = ({ logo, color }) => {
  return (
    <div>
      <Header logo={logo} color={color} />

      <Banner />
      <div className="about-page-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 text-section-title">
              <h2 className="section-title align-items-start">
                <div className="title-content">
                  ABOUT<span className="bold-title"> US</span>
                </div>
                <div className="under-section-title">
                  <div className="left-line"></div>
                  <div className="right-line"></div>
                </div>
              </h2>
              <p className="text text-italic">
                <span>
                  Our goal is to help people take their business to the next
                  level.
                </span>
              </p>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12">
              <div className="about-page-content">
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Team />
      <Footer />
    </div>
  );
};

export default AboutPage;
