import React from "react";


function ServiceItem({data}) {
  return (
        <div className={"row service-image-" + data.position}>
          <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 service-icon">
            <div className="div-image">  <img src={data.image} /></div>
          </div>
          <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12">
            <h2 className={"section-title"}>
                    <div className="title-content"><span className="bold-title"> {data.boldTitle}</span>{data.regularTitle}</div>
                    <div className="under-section-title">
                    <div className="left-line"></div>
                    <div className="right-line"></div>
                    </div>
            </h2> 
                <p>
                    {data.description}
                </p>

                <a href={data.url} className="bn--learn-btn inner-learn-btn">
                  Learn More
                </a>
          </div>
        </div>
     
  );
}
export default ServiceItem;