import React from "react";
import Routes from "../../../Routing";
import { useRoutes } from "hookrouter";

function Navbar({ logo, color }) {
  const showMobileMenu = () => {
    document
      .querySelector(".bn--mobile-menu-content")
      .classList.toggle("showed");
    document.body.classList.toggle("menu-opened");
  };
  useRoutes(Routes);
  return (
    <div className="bn--React-container">
      <header className="bn--header">
        <nav className={`container bn--navbar bn--navbar-${color} primary-bg`}>
          <a href="/" className="bn--logo" style={{ color: color }}>
            <figure className="img-holder">
              <img
                src={
                  color === "black"
                    ? "/images/site-logo.png"
                    : "/images/White.png"
                }
                alt="Artech Business Solutions"
              />
            </figure>
          </a>

          <ul className="bn--menu navbar-nav">
            <li className="nav-item bn--dropdown">
              <a
                href="/services"
                className="nav-link bn--dropdown-toggle"
                style={{ color: color }}
              >
                Services
              </a>
              <div className="bn--submenu dropdown-menu">
                <a href="/software" className="dropdown-item">
                  Software
                </a>

                <a href="/hardware" className="dropdown-item">
                  Hardware
                </a>
                {/* <a href="/marketing" className="dropdown-item">
                  Marketingafs
                </a> */}
              </div>
            </li>
            <li className="nav-item">
              <a href="/about-us" className="nav-a" style={{ color: color }}>
                {" "}
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a href="/portfolio" className="nav-a" style={{ color: color }}>
                Portfolio
              </a>
            </li>
            <li className="nav-item">
              <a href="/contact-us" className="nav-a" style={{ color: color }}>
                Contact Us
              </a>
            </li>

            <img
              width="40"
              height="30"
              src={
                color === "black"
                  ? "/images/blackSBA.png"
                  : "/images/whiteSBA.png"
              }
            />

            <li className="nav-item">
              {/* <a href="/faq" className="nav-a" style={{ color: color }}>
                FAQ
              </a> */}
            </li>
          </ul>
          <div className="bn--mobile-menu">
            <div className="bn--burger-btn" onClick={() => showMobileMenu()}>
              <span className="burder-line-item"></span>
              <span className="burder-line-item"></span>
              <span className="burder-line-item"></span>
            </div>
            <div className="bn--mobile-menu-content">
              <div className="mobile-menu-header">
                <a className="close-btn" onClick={showMobileMenu}></a>
              </div>
              <ul>
                <li className="nav-item bn--dropdown">
                  <a
                    href="/services"
                    className="nav-link bn--dropdown-toggle"
                    style={{ color: color }}
                  >
                    Services
                  </a>
                  <div className="bn--submenu dropdown-menu">
                    <a href="/software" className="dropdown-item">
                      Software
                    </a>

                    <a href="/hardware" className="dropdown-item">
                      Hardware
                    </a>
                    {/* <a href="/marketing" className="dropdown-item">
                      Marketing
                    </a> */}
                  </div>
                </li>
                <li className="nav-item">
                  <a
                    href="/about-us"
                    className="nav-a"
                    style={{ color: color }}
                  >
                    {" "}
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/portfolio" className="nav-a">
                    Portfolio
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="/contact-us"
                    className="nav-a"
                    style={{ color: color }}
                  >
                    Contact Us
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a href="/faq" className="nav-a" style={{ color: color }}>
                    FAQ
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
export default Navbar;
