import React from "react";
import ProductDetails from "../ProductDetails";

import SupportPlan from "../SuportPlan/supportPlan";
import Header from "../../header";
import ContactUs from "../../contactUs";
import Footer from "../../footerfolder";

const HardwarePage = ({ logo, color }) => {
  return (
    <div>
      <Header logo={logo} color={color} />
      <ProductDetails
        image="/images/Group122.png"
        productName="hardware"
        boldTitle="Hard"
        regularTitle="Ware"
        description="We are a VAR and distributor of industrial networking devices, Wireless Broadband Equipment and Wireless and Industrial Internet of Things (IIOT) related solutions."
      />
      {/* <SupportPlan productName="hardware" /> */}
      <div className="contact-section">
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
};

export default HardwarePage;
