import React from "react";
import ContactUs from "../contactUs";

import Header from "../header";
import Footer from "../footerfolder";
const ContactPage = ({ logo, color }) => {
  return (
    <div>
      <Header logo={logo} color={color} />

      <div className="contact-page-container">
        <div className="container">
          <div className="contact-page-title-container">
            <h2 className="section-title align-items-start">
              <div className="title-content">
                CONTACT<span className="bold-title"> US</span>
              </div>
              <div className="under-section-title">
                <div className="left-line"></div>
                <div className="right-line"></div>
              </div>
            </h2>
            <div className="footer-icon-label">Phone: +1 818-730-1315</div>
            {/* <div className="footer-icon-content">Mobile: +1 916-875-2235</div> */}
          </div>
        </div>
        <section className="bn--section contact-page-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 m-auto support-container">
                <ContactUs />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
