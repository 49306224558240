import React from "react";
import Header from "../header";
import ServiceItems from "./serviceItems";
import { ServiceItemsData } from "./serviceItemsData";
import FaQ from "../faq";
import Footer from "../footerfolder";

function ServicesPage({ logo, color }) {
  const ServiceData = ServiceItemsData;
  console.log(ServiceData);
  return (
    <div>
      <div className="service-page ">
        <Header logo={logo} color={color} />
        <div className="container">
          <div className="row our-service">
            <div className="col-md-6 col-xs-6 col-sm-12 col-lg-6	col-xl-6  left">
              <div className="service-page-title">
                <span className="bold-size">Services</span>
              </div>
              <div className="service-page-text">
                The team takes care of everything from UI/UX design to the end
                goal of building an efficient application for your business.
              </div>
            </div>
            <div className="col-md-6 col-xs-6 col-sm-12 col-lg-6	col-xl-6 right">
              <div className="servise-page-right">
                {" "}
                <img
                  src="images/service/image_26.png"
                  className="our-service-png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {ServiceItemsData.map((data, i) => (
          <ServiceItems key={i} data={data} />
        ))}
      </div>
      {/* < FaQ /> */}
      <Footer />
    </div>
  );
}
export default ServicesPage;
