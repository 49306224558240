import React from "react";
function Footer() {
  return (
    <footer>
      <div className="footerTop">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 m-auto">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-xs-12 col-sm-12 col-foot_left bn-footer-items footer-icons text-center">
                  <div>
                    <img src="/images/icons/group_46.svg" />
                  </div>
                  <div className="footer-icon mt-2">Email</div>
                  <div className="footer-icon-label "></div>
                  <div className="footer-icon-content">
                    arvin@artechsolutionsinc.com
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-12 col-sm-12 foot_center bn-footer-items footer-icons text-center">
                  <div>
                    <img src="/images/icons/group_49.svg" />
                  </div>
                  <div className="footer-icon mt-2">Location</div>
                  <div className="footer-icon-label ">Los Angeles, CA</div>
                </div>
                <div className="col-lg-4 offset-lg-0 col-md-6 offset-md-3 col-xs-12 col-sm-12 foot_right bn-footer-items footer-icons text-center">
                  <div>
                    <img src="/images/icons/group_50.svg" />
                  </div>
                  <div className="footer-icon mt-2">Phone</div>
                  <div className="footer-icon-label">Phone: 1 818-730-1315</div>
                  <div className="footer-icon-content">
                    {/* Mobile: +1 916-875-2235 */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerBottom">
        <div className="container">
          <div className="row bn-footer-columns">
            <div className="col-md-4 col-xs-4 col-sm-4 bn--footer-column bn--footer-column-1">
              <div className="div-foot-1">
                <a href="/services">Services</a>
              </div>
              <div className="div-foot">
                <a href="/hardware">HardWare</a>
              </div>
              <div className="div-foot">
                <a href="/software">SoftWare</a>
              </div>
              {/* <div className="div-foot">
                <a href="/marketing">Marketing</a>

              </div> */}
            </div>
            <div className="col-md-4 col-xs-4 col-sm-4 bn--footer-column bn--footer-column-2">
              <div className="div-foot-2">
                <a href="/about-us"> About Us</a>
              </div>
              <div className="div-foot ">
                <a href="/contact-us">Contact Us</a>
              </div>

              {/* <div className="div-foot ">
                <a href="/faq">FAQ</a>
              </div> */}
            </div>
            <div className="col-md-4 col-xs-4 col-sm-4 bn--footer-column bn--footer-column-3">
              <div className="div-foot1">Follow us on</div>
              <div className="div-foot-icon mt-3">
                <a href="#">
                  <img
                    src="/images/sociaIicons/instagram.svg"
                    className="icon "
                  />
                </a>
                <a href="#">
                  <img src="/images/sociaIicons/f.svg" className="icon" />
                </a>
                <a href="https://www.linkedin.com/in/arvin-abrahamian-890245161/">
                  <img
                    src="/images/sociaIicons/linkedin-in.svg"
                    className="icon"
                  />
                </a>

                {/* <a href=""> </a>
                  <img src="/images/sociaIicons/youtube.svg" className="icon" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
