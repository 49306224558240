import React, {useState} from "react";
import FaqItem from "./faqItem";
function FaQ() {
  const [faqs, setfaqs] = useState([
    {
      question: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
      answer: "Custom Answer in This question",
      open: true
    },
    {
      question: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
      answer: "Custom Answer in This question",
      open: false
    },
    {
      question: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
      answer: "Custom Answer in This question",
      open: false
    }
  ]);

  const toggleFaq = index => {
    setfaqs(
      faqs.map((faq,i) => {
        if(i === index){
          faq.open = !faq.open
        }else{
          faq.open = false;
        }
        return faq;
      })
    )
  }
  return (
    <section className="bn--section bn-faq-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12  faq-left">
              <h2 className="section-title align-items-start"><div className="title-content">FAQ</div><div className="under-section-title"><div className="left-line"></div><div className="right-line"></div></div></h2>
              <p>Most common answer and question</p>
              <a href="#" className="bn--learn-btn inner-learn-btn">Learn More</a>
            </div>
            <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 faq-right">
              <div className="faqs"> 
              {faqs.map((faq,i) => (
                
                <FaqItem key={i} faq={faq} index={i} toggleFaq={toggleFaq} />
                
                  // <FaqItem faq={faq} index={i} />
              ))}
            </div>
            </div>
          </div>
        </div>
</section>
  );
}
export default FaQ;
