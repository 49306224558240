const ImgWithTextInnerRows = ({img, boldtitle, regularTitle, description, url}) => {
    return (
        <div className="row align-items-center inner-element-row">
              <div className="col-lg-2 col-md-2 col-xs-12 col-sm-12 icon-content ">
                <img src={img} className="img-fluid" />
              </div>
              <div className="col-lg-10 col-md-10 col-xs-12 col-sm-12 text-content">
                <div className="text-content-title">
                  <span className="bold-title inner-bold-title">{boldtitle}</span>{regularTitle}
                </div>
                <p>
                  {description}
                </p>
                <a href={url} className="bn--learn-btn inner-learn-btn">
                  Learn More
                </a>
              </div>
        </div>
    )
}

export default ImgWithTextInnerRows;